import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Text from "components/Text";
import { Helmet } from "react-helmet";
import blankaLogo from "assets/images/logo.png";
import Card from "@mui/material/Card";

import { setToken } from "state/sessionSlice";
import styles from "./ShopifyLogin.module.scss";

export default function ShopifyLogin() {
  const dispatch = useDispatch();

  const isLoggedIn = useSelector((state) => state.session.isLoggedIn);

  const navigate = useNavigate();

  // save shop url for when they login
  useEffect(() => {
    /// /// connection a store  /////////
    const storeURL = new URLSearchParams(window.location.search).get(
      "shop_url"
    );
    const storeToken = new URLSearchParams(window.location.search).get("token");
    if (storeURL && storeToken) {
      window.sessionStorage.setItem(
        "shopify_store",
        JSON.stringify({ url: storeURL, token: storeToken })
      );
    }
    /// ////////////////////////////////

    const accessToken = new URLSearchParams(window.location.search).get(
      "access_token"
    );
    const refreshToken = new URLSearchParams(window.location.search).get(
      "refresh_token"
    );

    const redirectSignup = new URLSearchParams(window.location.search).get(
      "redirect_signup"
    );

    // if token set param
    if (storeToken && storeURL) {
      navigate(
        `/profile/connect${redirectSignup ? "?redirect_signup=true" : ""}`
      );
      return;
    }

    // first time accessing, redirect to signup page
    if (redirectSignup && !accessToken && !refreshToken) {
      navigate("/signup?source=shopify");

      return;
    }

    if (accessToken && refreshToken) {
      dispatch(setToken({ access: accessToken, refresh: refreshToken }));
      navigate("/?connect_store=true&source=shopify");
    }

    // else {
    //  console.log("no token");
    // navigate("/?connect_store=true&source=shopify");
    // }
  }, []);

  return (
    <div className={styles.templateLogin}>
      <Helmet>
        <title>login - blanka</title>
        <meta
          name="description"
          content="Official app login page app for Shopify, WooCommerce and more"
        />
      </Helmet>

      <Card className={styles.card} variant="outlined">
        <div className={styles.welcomeContainer}>
          <Text
            className="text--bold text--nunito text--primary text--spacing-6"
            fontSize={34}
            variant="h4"
          >
            WELCOME TO{" "}
          </Text>
          <img src={blankaLogo} alt="Logo" className={styles.blankaLogo} />
        </div>

        <div className={styles.headerTextContainer}>
          <Text fontSize={24} className="text--blue text--bold">
            Please login to access the app.
          </Text>
          <Link to="/login" className={`${styles.textLink} `}>
            Login
          </Link>
        </div>
      </Card>
    </div>
  );
}
