import { Box, Divider } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import {
  connectStore,
  connectWixStore,
  connectWoocommerceStore,
  getUser,
  updateShop,
} from "api";
import bigCommerceLogo from "assets/images/big-commerce-logo.png";
import shopifyLogo from "assets/images/shopify-logo.png";
import wixLogo from "assets/images/wix-logo.png";
import wooCommerceLogo from "assets/images/woocommerce_logo.png";
import Button from "components/Buttons/Button";
import Text from "components/Text";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { setProfile } from "state/profileSlice";

import BlankaLogo from "assets/images/blanka-lipstick-your-logo-gif.gif";
import WhiteBlankaLogoImg from "assets/images/blanka-white.png";
import LottieConfettie from "assets/images/lottie-confettie.gif";

// import LazyLoad, { forceVisible } from "react-lazyload";
import { useAnalytics } from "hooks";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import SidePanelOnboardingStepB from "../components/SidePanelOnboardingStepB/SidePanelOnboardingStepB";
import OnboardingWrapperB from "../OnboardingWrapperB";
import styles from "./OnboardingStepFourB.module.scss";

export default function OnboardingStepFourB() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const analytics = useAnalytics();
  const shop = useSelector((state) => state.profile.shop);
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!shop) {
      return;
    }

    const setData = async (store) => {
      try {
        await connectStore({ token: store.token, url: store.url });
        const response = await getUser();
        analytics.sendEvent(analytics.ONBOARDING_CONNECT_STORE_SUCCESS, {
          url: store.url,
        });
        dispatch(setProfile(response.data));

        sessionStorage.removeItem("shopify_store");
        analytics.sendEvent(analytics.ONBOARDING_COMPLETE, {
          plan_id: shop.plan,
        });
        setLoading(false);
      } catch (e) {
        setError("There was a problem connecting your store, please try again");
        sessionStorage.removeItem("shopify_store");
        setLoading(false);
      }
    };

    const connectWoocommerce = async (store) => {
      try {
        await connectWoocommerceStore({ token: store.token, url: store.url });
        const response = await getUser();
        dispatch(setProfile(response.data));
        analytics.sendEvent(analytics.ONBOARDING_CONNECT_STORE_SUCCESS, {
          url: store.url,
          plan_id: shop.plan_id,
        });
        sessionStorage.removeItem("woocommerce_store");
        analytics.sendEvent(analytics.ONBOARDING_COMPLETE, {
          plan_id: shop.plan,
        });
        setLoading(false);
      } catch (e) {
        setError("There was a problem connecting your store, please try again");
        sessionStorage.removeItem("woocommerce_store");
        setLoading(false);
      }
    };

    const connectWix = async (store) => {
      try {
        await connectWixStore({ url: store.url });
        const response = await getUser();
        dispatch(setProfile(response.data));
        analytics.sendEvent(analytics.ONBOARDING_CONNECT_STORE_SUCCESS, {
          url: store.url,
          plan_id: shop.plan_id,
        });
        sessionStorage.removeItem("wix_store");
        analytics.sendEvent(analytics.ONBOARDING_COMPLETE, {
          plan_id: shop.plan,
        });
        setLoading(false);
      } catch (e) {
        setError("There was a problem connecting your store, please try again");
        sessionStorage.removeItem("woocommerce_store");
        setLoading(false);
      }
    };

    // check for shopify store connection
    const shopifyStore = JSON.parse(sessionStorage.getItem("shopify_store"));
    if (shopifyStore) {
      setLoading(true);
      setData(shopifyStore);
    }

    // check for woocomerce store connection
    const woocommerceStore = JSON.parse(
      sessionStorage.getItem("woocommerce_store")
    );
    if (woocommerceStore) {
      setLoading(true);
      connectWoocommerce(woocommerceStore);
    }

    // check for wix store connection
    const wixStore = JSON.parse(sessionStorage.getItem("wix_store"));
    if (wixStore) {
      setLoading(true);
      connectWix(wixStore);
    }
  }, []);

  const handleSkip = async () => {
    setLoading(true);
    analytics.sendEvent(analytics.ONBOARDING_CONNECT_STORE_SKIP, {
      plan_id: shop.plan,
    });
    analytics.sendEvent(analytics.ONBOARDING_COMPLETE, {
      plan_id: shop.plan,
    });
    await updateShop(shop.id, { onboarding_step: 5, plan_id: shop.plan });
    navigate("/");
  };

  const connectStoreAttempt = (integrationType) => {
    analytics.sendEvent(analytics.ONBOARDING_CONNECT_STORE_ATTEMPT, {
      integration_type: integrationType,
    });

    switch (integrationType) {
      case "shopify":
        window.open("https://apps.shopify.com/blanka");
        break;
      case "woocommerce":
        window.open("https://wordpress.org/plugins/blanka/");
        break;
      case "wix":
        window.open(
          "https://www.wix.com/app-market/beauty-dropshipping-by-blanka"
        );
        break;
      default:
        break;
    }
  };

  return (
    <Box className={styles.onboardingStepFourBContainer}>
      <Box className={styles.sideBox}>
        <Box className={styles.welcomeContainer}>
          <LazyLoadImage
            src={BlankaLogo}
            alt="blanka logo"
            className={styles.blankaLogoGif}
          />
          <LazyLoadImage
            src={WhiteBlankaLogoImg}
            alt="blanka logo white"
            className={styles.blankaLogo}
          />
        </Box>
      </Box>
      <Box
        className={styles.onboardingStepFourB}
        data-testid="OnboardingStepTwoB"
      >
        <Box className={styles.confettiGif}>
          <Box className={styles.upContainer}>
            <Text className={styles.title}>
              We’re super excited to help you
              <br /> bring your brand to life!
            </Text>

            <Button
              variant="contained"
              color="primary"
              loading={loading}
              className={styles.skipButton}
              onClick={handleSkip}
              hasChevron
            >
              Build your product line
            </Button>
          </Box>
        </Box>
        <div className={styles.divider}>OR</div>

        {loading && (
          <Box className={styles.loading}>
            <CircularProgress />
          </Box>
        )}

        <Box>
          {!loading && (
            <Box>
              <Text className={styles.onlineShop}>
                Already have an online store?
              </Text>
              <Text className={styles.onlineShopText}>
                Select a platform below to connect your store with one click.{" "}
              </Text>
              <Box className={styles.storesContainer}>
                <Box
                  className={`${styles.storeContainer} ${styles.storeContainerActive}`}
                >
                  <a
                    target="_blank"
                    onClick={() => connectStoreAttempt("shopify")}
                  >
                    <LazyLoadImage
                      src={shopifyLogo}
                      alt="shopify logo"
                      width="125"
                      height="125"
                      effect="black-and-white"
                      visibleByDefault
                      placeholderSrc={shopifyLogo}
                    />
                  </a>
                  <Text
                    fontSize={12}
                    className={`text--nunito text--bold ${styles.textShopType}`}
                  >
                    Shopify
                  </Text>
                </Box>
                <Box
                  className={`${styles.storeContainer} ${styles.storeContainerActive}`}
                >
                  <a
                    target="_blank"
                    onClick={() => connectStoreAttempt("woocommerce")}
                  >
                    <LazyLoadImage
                      src={wooCommerceLogo}
                      alt="woocommerce logo"
                      width="125"
                      height="125"
                      effect="black-and-white"
                      visibleByDefault
                      placeholderSrc={wooCommerceLogo}
                    />
                  </a>

                  <Text
                    fontSize={12}
                    className={`text--nunito text--bold ${styles.textShopType}`}
                  >
                    WooCommerce
                  </Text>
                </Box>

                <Box
                  className={`${styles.storeContainer} ${styles.storeContainerActive}`}
                >
                  <a target="_blank" onClick={() => connectStoreAttempt("wix")}>
                    <LazyLoadImage
                      src={wixLogo}
                      alt="wix logo"
                      width="125"
                      height="125"
                      effect="black-and-white"
                      visibleByDefault
                      placeholderSrc={wixLogo}
                    />
                  </a>

                  <Text
                    fontSize={12}
                    className={`text--nunito text--bold ${styles.textShopType}`}
                  >
                    Wix
                  </Text>
                </Box>
                <Box
                  className={`${styles.storeContainer} ${styles.storeContainerInactive}`}
                  style={{ marginRight: "0px" }}
                >
                  <LazyLoadImage
                    src={bigCommerceLogo}
                    alt="bigcommerce logo"
                    width="125"
                    height="125"
                    effect="black-and-white"
                    visibleByDefault
                    placeholderSrc={bigCommerceLogo}
                  />

                  <Text
                    fontSize={15}
                    className={`${styles.textComingSoon} text--bold text--white text--spacing-4`}
                  >
                    Coming Soon
                  </Text>
                  <Text
                    fontSize={12}
                    className={`text--nunito text--bold ${styles.textShopType}`}
                  >
                    BigCommerce
                  </Text>
                </Box>
              </Box>
            </Box>
          )}

          <Box className={styles.noticeContainer}>
            <Text fontSize={12} color="gray">
              P.S you can still use Blanka with SquareSpace, BigCommerce or
              other e-commerce platforms.{" "}
              <a
                target="__blank"
                href="https://faq.blankabrand.com/en/articles/5265856-how-do-i-use-blanka-with-ecommerce-platforms-like-wix-and-woocommerce"
                className={`${styles.textRedirect} text--bold text--info`}
              >
                Learn more.
              </a>
            </Text>

            <Text fontSize={12} color="error">
              {error}
            </Text>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
